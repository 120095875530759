import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function RefundPolicy() {
  const navigate = useNavigate();
  const { id } = useParams();
  function handleTransactionId() {
    navigate(id === undefined ? "/refund" : `/refund/${id}`);
    window.scrollTo(0, 0);
  }

  return (
    <div className="w-full h-full flex flex-col justify-start items-center text-white mt-6">
      <h1 className="text-center font-anton font-[20px]">
        Cancellation and Refund Policy for Cricket8.com Effective Date: 1st
        December 2024
      </h1>
      <div>
        <h1 className="text-center font-anton font-[20px] mt-5">
          1. Payment and Billing
        </h1>
        <h1 className="text-start font-anton font-[20px] mt-1">
          1.1 Payment Overview
        </h1>
        <p className="text-[16px] font-[400] text-gray-300 mb-1">
          Our website offers products that can be purchased using Google Pay. By
          completing a payment, you confirm that you are authorised to use the
          selected payment method and that all payment details provided are
          accurate.
        </p>
      </div>
      <div>
        <h1 className="text-start font-anton font-[20px] mt-1">
          1.2 Transaction Processing
        </h1>
        <p className="text-[16px] font-[400] text-gray-300 mb-1">
          Payments made through Google Pay are processed in accordance with
          Google Pay’s Terms of Service. Please refer to these terms for more
          details about the transaction process and any associated requirements.
        </p>
        <h1 className="text-start font-anton font-[20px] mt-1">1.3 Pricing</h1>
        <p className="text-[16px] font-[400] text-gray-300 mb-1">
          All prices displayed on our website are inclusive of applicable taxes
          unless stated otherwise. Prices are subject to change at any time
          without prior notice.
        </p>
      </div>
      <div className="w-full">
        <h1 className="text-center font-anton font-[20px] mt-5">2. Refunds</h1>
        <h1 className="text-start font-anton font-[20px] mt-1">
          2.1 Eligibility for Refunds
        </h1>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          <span className="font-bold">1.</span> Refund requests must comply with
          this policy and Google Pay’s refund policies.
        </p>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          <span className="font-bold">2.</span>Refunds will only be issued to
          the original payment method used at the time of purchase.
        </p>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          <span className="font-bold">3.</span> Refunds for Ruby Pack purchases
          only apply to Packs which have not yet been spent in full or part, on
          Coins.
        </p>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          <span className="font-bold">4.</span> We cannot offer part refunds for
          unspent Rubies.
        </p>
      </div>
      <div>
        <h1 className="text-start font-anton font-[20px] mt-5">
          2.2 How to Request a Refund
        </h1>
        <p className="text-[16px] font-bold text-gray-300 mt-1">
          To request a refund:
        </p>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          <span className="font-bold">1.</span> Log in to your Google Pay
          account.
        </p>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          <span className="font-bold">2.</span>Navigate to your Order History
          and find the specific transaction.
        </p>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          <span className="font-bold">3.</span>Select the option to Request a
          Refund and provide the required details.
        </p>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          <span className="font-bold">4.</span> The refund request will be sent
          to us for review and processing. If you encounter issues while
          requesting a refund through Google Pay, contact our support team here:
          [Insert Link to Contact Form] for further assistance. If you enquiry
          relates to a recent purchase, please ensure you include the
          Transaction ID which you can find on your Account page.
        </p>
        <h1 className="text-start font-anton font-[20px] mt-5">
          2.3 Refund Timelines
        </h1>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          Approved refunds will be processed within 5-7 business days, depending
          on your bank or card issuer's policies.
        </p>
        <h1 className="text-start font-anton font-[20px] mt-5">
          2.4 Non-Refundable Items
        </h1>
        <p className="text-[16px] font-bold text-gray-300 mt-1">
          Refunds will not be issued for the following:
        </p>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          <span className="font-bold">1.</span> Digital goods or services that
          have been accessed, used or downloaded
        </p>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          <span className="font-bold">2.</span>Items marked as "final sale" or
          otherwise non-refundable at the time of purchase.
        </p>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          <span className="font-bold">3.</span>Issues caused by user error or
          misuse of the product or service.
        </p>
      </div>
      <div>
        <h1 className="text-center font-anton font-[20px] mt-5">
          3. Unauthorised Transactions
        </h1>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          If you believe a transaction was unauthorised, immediately report it
          to Google Pay via the Google Pay Help Center. We will work with Google
          Pay to resolve the issue.
        </p>
      </div>
      <div>
        <h1 className="text-center font-anton font-[20px] mt-5">
          4. Changes to This Policy
        </h1>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          We may update this Cancellation and Refund Policy from time to time.
          Updates will be posted on our website, and significant changes will be
          communicated to you via email or other means. Continued use of our
          website after updates constitutes acceptance of the revised policy.
        </p>
      </div>
      <div>
        <h1 className="text-center font-anton font-[20px] mt-5">
          5. Contact Information
        </h1>
        <p className="text-[16px] font-[400] text-gray-300 mt-1">
          If you have any questions, concerns, or refund requests, please
          contact us{" "}
          <Link
            className="underline font-bold"
            to="#"
            onClick={handleTransactionId}
          >
            here:
          </Link>
        </p>
      </div>
    </div>
  );
}
