import React, { useState, useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useParams } from "react-router-dom";

export default function Refund() {
  const nameRef = useRef<HTMLInputElement | null>(null);
  const emailRef = useRef<HTMLInputElement | null>(null);
  const messageRef = useRef<HTMLTextAreaElement | null>(null);
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [recaptchaError, setRecaptchaError] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const { id } = useParams();
  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const handleMessage = async () => {
    setEmailError("");
    setMessageError("");
    setRecaptchaError("");
    setNameError("");
    const email = emailRef.current?.value || "";
    const name = nameRef.current?.value || "";
    const message = messageRef.current?.value || "";
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
    }
    if (name === "") {
      setNameError("Please enter your name.");
    }
    if (message === "") {
      setMessageError("Please enter your message.");
    }
    if (!captchaValue) {
      setRecaptchaError("Error verifying reCaptcha.");
    }
    const headers = {
      "Content-Type": "application/json",
    };
    headers["x-api-key"] = process.env.API_KEY;
    if (validateEmail(email) && name !== "" && message !== "" && captchaValue) {
      setSending(true);
      const response = await fetch(`${process.env.API_URL}/register/message`, {
        method: "post",
        headers: headers,
        body: JSON.stringify({
          name: name,
          email: email,
          message: message,
          captchaValue: captchaValue,
        }),
      });
      if (response.status === 200) {
        const data = await response.json();
        if (data.success === true) {
          setSuccess(true);
        } else {
          setSuccess(false);
          setRecaptchaError("Message failed to send.");
          setSending(false);
        }
      }
    }
  };

  useEffect(() => {
    const updatePageTitle = () => {
      document.title = "CONTACT US | Cricket8";
    };
    updatePageTitle();
    return () => {};
  }, []);

  function onChange(value) {
    setCaptchaValue(value);
  }

  return (
    <div id="content" className="min-h-[75vh] mt-[0px] md:mt-[20px]">
      <h2 className="text-[14px] md:text-[20px]">Cricket8</h2>
      <h1 className="text-[30px] md:text-[55px]">
        {id ? "Request a refund" : "CONTACT US"}
      </h1>

      <div className="mt-[60px] font-chakra font-[#fff] text-[16px] bg-[#452f5b] p-[20px] md:flex">
        <div className="bg-[#d2ff00] flex flex-col justify-center items-center h-[120px] w-full md:w-[150px] text-[#1b062f] text-[60px] text-center pt-[20px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7"
            />
          </svg>
          <div className="text-[16px] font-chakra">Address</div>
        </div>
        <div className="mt-[20px] md:mt-[0px] md:ml-[50px]">
          <p>
            <div className="bold mb-[6px]">
              Pukka Entertainment Private Limited
            </div>
            <div>
              Registered Address:
              <br />
              We Work Vaswani Chambers, 2nd Floor, 264-265, Dr Annie Beasant
              Road, Municipal Colony, Worli Shivaji Nagar, Mumbai, Maharashtra,
              400025/30
            </div>
            <div>
              India Company Number: U63910MH2024FTC428666
            </div>
          </p>
          <br />
          <p>
            <div className="bold mb-[6px]">
              Pukka Entertainment Limited (Trading as Cricket8)
            </div>
            <div>
              Registered Address:
              <br />
              5th Floor, 167-169 Great Portland Street, London, W1W 5PF
              <br />
              Phone +44 (0) 208 135 9165
            </div>
            <div>
              U.K. Registered Company Number: 14138673
            </div>
          </p>
        </div>
      </div>

      <div className="mt-[60px] pb-[40px] font-chakra font-[#fff] text-[16px] bg-[#452f5b] p-[20px] md:flex">
        <div className="bg-[#d2ff00] flex flex-col justify-center items-center h-[120px] w-full md:w-[150px] text-[#1b062f] text-[60px] text-center pt-[20px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2zm-2 0l-8 5l-8-5zm0 12H4V8l8 5l8-5z"
            />
          </svg>
          <div className="text-[16px] font-chakra">Message</div>
        </div>
        <div className="mt-[20px] md:mt-[0px] md:ml-[50px] md:mr-[50px] flex-grow">
          <div className="w-full h-[40px] bg-white border border-solid border-[#fff] mb-[30px]">
            <input
              ref={nameRef}
              className="h-full w-full enteremail2"
              type="text"
              placeholder="your name"
            />
            {nameError && (
              <div
                style={{
                  color: "#f73261",
                  paddingTop: "5px",
                  paddingLeft: "20px",
                }}
              >
                {nameError}
              </div>
            )}
          </div>

          <div className="w-full bg-white h-[40px] border border-solid border-[#fff] mb-[30px]">
            <input
              ref={emailRef}
              className="h-full w-full enteremail2"
              type="email"
              placeholder="enter your email"
            />
            {emailError && (
              <div
                style={{
                  color: "#f73261",
                  paddingTop: "5px",
                  paddingLeft: "20px",
                }}
              >
                {emailError}
              </div>
            )}
          </div>

          <div className="w-full h-[100px] bg-white border border-solid border-[#fff] mb-[30px]">
            <textarea
              ref={messageRef}
              className=" w-full h-full enteremail2"
              placeholder="your message"
              defaultValue={
                id ? `I’m asking for a refund for transaction number ${id}` : ""
              }
            />
            {messageError && (
              <div
                style={{
                  color: "#f73261",
                  paddingTop: "5px",
                  paddingLeft: "20px",
                }}
              >
                {messageError}
              </div>
            )}
          </div>
          <div>
            <ReCAPTCHA
              sitekey="6LcNubcpAAAAADIenWKHvGywBH0hNAL55T9yrqL8"
              onChange={onChange}
            />
            {recaptchaError && (
              <div
                style={{
                  color: "#f73261",
                  paddingTop: "5px",
                  paddingLeft: "20px",
                }}
              >
                {recaptchaError}
              </div>
            )}
          </div>

          {success ? (
            <div className="text-[#32f78c] font-anton text-[24px] pt-[20px]">
              Message sent!
            </div>
          ) : null}
          {!sending ? (
            <div
              className="mt-[20px] h-[52px] w-[110px] flex flex-row justify-center items-center bg-[#d2ff00] font-anton text-[20px] text-[#1b062f] cursor-pointer"
              onClick={handleMessage}
            >
              <div>SEND</div>
              <div className="ml-[15px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="-5 -5 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m10.586 5.657l-3.95-3.95A1 1 0 0 1 8.05.293l5.657 5.657a.997.997 0 0 1 0 1.414L8.05 13.021a1 1 0 1 1-1.414-1.414l3.95-3.95H1a1 1 0 1 1 0-2z"
                  />
                </svg>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
